#ReportPageContainer {
  width: 100%;
  height: calc(100vh - 30px);
  overflow: hidden;
  /* margin-top: -70px; */
}

#ReportContainer {
  /* margin-top: -5px; */
  margin-bottom: 18px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  /* height: 3.5%; */
  align-items: center;
}

#ReportContainerOptions {
  display: flex;
  margin-right: 10px;
  padding-right: 5px;
  /* border-right: 1px solid #cecece; */
  align-items: center;
}

.imageButtonReport {
  height: 15px;
  width: 15px;
  fill: white;
}

#reportDesign {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#panel {
  background-color: #04110A;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  height: 30px;
}

.panelFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #ReportPageContainer {
    display: grid;
    flex-direction: column;
  }

  #ReportContainer {
    justify-content: space-between;
  }

  #ReportContainerOptions {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  .imageButtonReport {
    height: 20px;
    width: 20px;
  }

  #panel {
    padding: 0 1rem;
  }
}

.report {
  overflow: hidden;
  width: 100%;
  overflow: hidden !important;

  @media (min-width: 600px) {
    height: calc(100vh - 30px);
  }
}

.reportMobile {
  width: 100%;
  background-color: white;
  overflow: hidden !important;
  height: calc(100vh - 30px);
}

.topbar {
  height: 30px;
  display: flex;
  align-items: center;
}
.topbar a {
  color: var(--color-white);
  font-size: 18px;
  padding: 0 20px;
}

#overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#alert {
  position: absolute;
  background-color: #04110A;
  height: 60px;
  font-size: 9pt;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5rem;
  padding: .5rem;
  border-radius: 6px;
  bottom: 10px;
}

#alert span {
  margin-left: 1rem;
  margin-right: .5rem;
}